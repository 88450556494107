.page{

}

.view {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    margin: 1em;
    margin-bottom: 20px;
    font-size: 4vw;
    width: 30svw;
    text-align: center;
}

.view:active {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

.views {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media only screen and (max-width: 600px) {
    .view {
        width: 80svw;
        font-size: 10vw;
    }
}
