.loginForm {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    color: white;
}

.page{
}

.loginForm form{
    display: contents;
}